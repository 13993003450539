// src/App.tsx
import 'react-toastify/dist/ReactToastify.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Layout from './components/Layout/Layout';
import ProtectedRoute from './components/ProtectedRoute';

// Code-splitting with React.lazy
const BusinessListPage = lazy(() => import('./pages/BusinessListPage'));
const BusinessCreatePage = lazy(() => import('./pages/BusinessCreatePage'));
const BusinessDetailPage = lazy(() => import('./pages/BusinessDetailPage'));


const App: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/businesses" replace />} />

                        <Route
                            path="/businesses"
                            element={
                                <ProtectedRoute>
                                    <BusinessListPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/businesses/create"
                            element={
                                <ProtectedRoute>
                                    <BusinessCreatePage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/businesses/:businessId"
                            element={
                                <ProtectedRoute>
                                    <BusinessDetailPage />
                                </ProtectedRoute>
                            }
                        />

                        {/* Add other routes, e.g. employees, items, etc. if you want separate pages */}
                    </Routes>
                </Suspense>
            </Layout>
            <ToastContainer position="top-right" autoClose={5000} />
        </Router>
    );
};

export default App;