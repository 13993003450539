import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const Header: React.FC = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" color="inherit">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    CallPad Demo Page
                </Typography>

                {/* Link to Business List */}
                <Button color="inherit" component={RouterLink} to="/businesses">
                    Businesses
                </Button>
                <Button color="inherit" component={RouterLink} to="/businesses/create">
                    Create
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
